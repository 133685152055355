/* *************** */
/*    FOOTER       */
/* *************** */

#footer {
  border-top: 1px black solid;
  padding: 0.5em;
  padding-top: 1em;
  /* animation-duration: 1s; */
  /* animation-name: slidein; */
  text-align: center;
}

#footer a {
  padding: 1em;
  text-decoration: none;
}

#footer i {
  font-size: 1.75em;
}

a {
  color: black;
}

.footerLink:hover {
  color: rgb(179, 243, 105);
}
