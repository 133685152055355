body {
  font-family: "Roboto Mono", monospace;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* *************** */
/* FRAMEWORK       */
/* *************** */

.body {
  /* text-align: center; */
  display: flex;
  justify-content: space-between;
  background: linear-gradient(
      to bottom,
      rgb(179, 243, 105),
      rgba(230, 74, 235, 0.85)
    )
    no-repeat center center fixed;
  min-height: 100vh;
}

.main {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 0.5em;
  margin: 1em;
  margin-right: 3em;
  margin-left: 7.5em;
}

aside {
  position: fixed;
  height: 100%;
  left: 5px;
}

/* *************** */
/* BARS ANIMATION  */
/* *************** */

#bars {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border: solid black 1px; */
}

#bar {
  background-color: rgb(229, 74, 235);
  height: 1vh;
  animation: center-animate 2s ease infinite alternate;
}

@keyframes center-animate {
  0% {
    transform: scaleX(1);
    transform-origin: left;
    background-color: rgba(230, 74, 235, 0.85);
  }
  50% {
    transform: scaleX(1.4);
    transform-origin: left;
    background-color: rgba(0, 0, 0, 0.6);
  }
  100% {
    transform: scaleX(1.8);
    transform-origin: left;
    background-color: rgba(179, 243, 105, 0.6);
  }
}

@media (max-width: 700px) {
  #bars {
    display: none;
  }
}

/* *************** */
/* FOR TESTING *** */
/* *************** */

main {
  /* border: 1pt green solid; */
}

main > * {
  /* border: 1pt blue solid; */
}
